export const defaultStyle = {
  page: {
    minHeight: '100vh',
  },
  marketingArea: {
    backgroundColor: 'var(--ebl-pagelayout-login-brand-color)',
  },
  pageContent: {
    backgroundColor: 'white',
  },
};

export const hnmStyle = {
  page: {
    minHeight: '100vh',
  },
  marketingArea: {
    backgroundColor: '#E3F7FA',
  },
  pageContent: {
    backgroundColor: 'white',
  },
};
