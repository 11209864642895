import { ALERT_SIZE, HcpAlert, SEVERITY } from '@hmhco/hcp-alert/src/index';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

/**
 * Should be used to display generic error messages from Okta form.
 *
 */
const OktaErrorAlert = () => {
  const [show, setShow] = useState(false);
  const errorAlertContainerRef = useRef(null);
  const mounted = useRef(false);
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (errorAlertContainerRef.current) {
      const observer = new MutationObserver(() => {
        if (errorAlertContainerRef.current) {
          const hidden = errorAlertContainerRef.current.getAttribute('hidden');
          setShow(hidden === null || hidden === undefined);
        }
      });
      observer.observe(errorAlertContainerRef.current, {
        attributes: true,
        attributeFilter: ['hidden'],
      });
      return () => {
        observer.disconnect();
      };
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }

    if (errorAlertContainerRef.current) {
      errorAlertContainerRef.current.hidden = !show;
    }
  }, [show]);

  return (
    <div
      hidden
      ref={errorAlertContainerRef}
      id="okta-error-alert"
      data-testid="okta-error-alert"
    >
      <HcpAlert
        size={ALERT_SIZE.LARGE}
        severity={SEVERITY.ERROR}
        title={formatMessage({ id: 'login.form.error.title' })}
        subtitle=""
        onClose={() => setShow(false)}
      />
    </div>
  );
};

export default OktaErrorAlert;
