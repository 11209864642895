import {
  updateLabels,
  updateForgotPassword,
  updateFields,
  updateBackButton,
  updateAlerts,
} from './utils';

export const updateWidget = (widgetRef, formatMessage) => {
  updateLabels(widgetRef);
  updateForgotPassword(widgetRef);
  updateFields(widgetRef);
  updateBackButton(widgetRef, formatMessage);
  updateAlerts(widgetRef);
};

export default updateWidget;
