import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'OktaForm' })(theme => ({
  changeButton: {
    backgroundColor: 'transparent',
  },
  widget: {
    '& main': {
      fontFamily: 'var(--ebl-wf-headings)',
    },
    '& .okta-form-title': {
      display: 'none',
    },
    '& a': {
      ':focus-visible': {
        outline: 'none',
        boxShadow: theme.palette.ebl.focusRing,
      },
    },
    '& input': {
      '-webkit-tap-highlight-color': 'transparent',
      padding: '8.5px 14px',
      width: '100%',
      height: '40px',
      borderRadius: '4px',
      border: 'none',
      fontWeight: 'var(--ebl-noto)',
      letterSpacing: '0.2px',
      fontSize: 'var(--ebl-sh2)',
      fontFamily: 'var(--ebl-wf-body)',
      ':focus-visible': {
        outline: 'none',
        boxShadow: theme.palette.ebl.focusRing,
      },
    },
    '& fieldset': {
      outline: 0,
      boxShadow: 'none',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.ebl.formelementBorderColor,
      textAlign: 'left',
      position: 'absolute',
      bottom: '0',
      right: '0',
      top: '0',
      left: '0',
      margin: '0',
      padding: '0 8px',
      pointerEvents: 'none',
      borderRadius: '4px',
      overflow: 'hidden',
      minWidth: '0%',
      '&.error': {
        borderWidth: 2,
        borderColor: theme.palette.ebl.formelementBorderColorError,
      },
    },
    '& .button': {
      '-webkit-box-pack': 'center',
      '-webkit-box-align': 'center',
      '-webkit-tap-highlight-color': 'transparent',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      boxSizing: 'border-box',
      cursor: 'pointer',
      userSelect: 'none',
      verticalAlign: 'middle',
      appearance: 'none',
      fontSize: 'var(--ebl-sh3)',
      height: 'var(--ebl-button-height-sm)',
      lineHeight: 'var(--ebl--line-height)',
      letterSpacing: 'var(--ebl-sh4-letter-spacing)',
      color: theme.palette.ebl.buttonContainedTextColor,
      textTransform: 'none',
      minWidth: 64,
      width: 'fit-content',
      backgroundColor: theme.palette.ebl.buttonContainedDefaultBackgroundcolor,
      fontFamily: 'var(--ebl-wf-headings)',
      fontWeight: 'var(--ebl-wf-headings-weight)',
      display: 'flex',
      boxShadow: 'none',
      outline: 0,
      borderWidth: 0,
      borderStyle: 'initial',
      borderColor: 'initial',
      borderImage: 'initial',
      textDecoration: 'none',
      padding: '7px var(--ebl-s4)',
      transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
      borderRadius: 'var(--ebl-button-radius)',
      margin: 'var(--ebl-s2) 0',
      '&:hover': {
        boxShadow: theme.palette.ebl.ButtonHoverShadow,
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '& .button-link': {
      '-webkit-box-pack': 'center',
      '-webkit-box-align': 'center',
      '-webkit-tap-highlight-color': 'transparent',
      width: 'auto',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      boxSizing: 'border-box',
      cursor: 'pointer',
      userSelect: 'none',
      verticalAlign: 'middle',
      appearance: 'none',
      fontSize: 'var(--ebl-sh3)',
      height: 'var(--ebl-button-height-sm)',
      lineHeight: 'var(--ebl--line-height)',
      letterSpacing: 'var(--ebl-sh4-letter-spacing)',
      color: theme.palette.ebl.buttonContainedTextColor,
      textTransform: 'none',
      minWidth: '64px',
      backgroundColor: theme.palette.ebl.buttonContainedDefaultBackgroundcolor,
      fontFamily: 'var(--ebl-wf-headings)',
      fontWeight: 'var(--ebl-wf-headings-weight)',
      display: 'flex',
      boxShadow: 'none',
      outline: 0,
      borderWidth: 0,
      borderStyle: 'initial',
      borderColor: 'initial',
      borderImage: 'initial',
      textDecoration: 'none',
      padding: '7px var(--ebl-s4)',
      transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
      borderRadius: 'var(--ebl-button-radius)',
      margin: 'var(--ebl-s2) 0',
      '&:hover': {
        boxShadow: theme.palette.ebl.ButtonHoverShadow,
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '& label': {
      fontSize: 'var(--ebl-sh2)',
      fontWeight: '600',
      color: theme.palette.ebl.formelementTextColor,
      lineHeight: 'var(--ebl-sh2-line-height)',
      '&.error': {
        color: theme.palette.ebl.formelementTextColorError,
      },
    },
    '& .o-form-button-bar': {
      display: 'flex',
      justifyContent: 'end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'start',
        flexDirection: 'column',
      },
    },
    '& [data-se="o-form-fieldset-rememberMe"]': {
      display: 'none',
    },
    '& [data-se="help"]': {
      display: 'none',
    },
    '& .o-form-label-top': {
      marginBottom: 'var(--ebl-input-marginbottom)',
    },
    '& .okta-form-input-error': {
      color: theme.palette.ebl.formelementTextColorError,
      marginLeft: 'var(--ebl-input-padding-left)',
      marginTop: 'var(--ebl-s1)',
      marginBottom: 0,
      marginRight: '14px',
      textAlign: 'left',
      fontWeight: '400',
      fontSize: '0.75rem',
      lineHeight: '1.66',
      fontFamily: 'var(--ebl-wf-headings)',
    },
    '& #forget-password-container': {
      display: 'flex',
      justifyContent: 'end',
      paddingBottom: 'var(--ebl-s2)',
    },
    '& #forget-password-paragraph': {
      fontSize: 'var(--ebl-p1)',
      margin: 0,
      fontFamily: 'var(--ebl-w-body)',
      lineHeight: 'var(--ebl-p2-line-height)',
      letterSpacing: 'var(--ebl-p1-letter-spacing)',
      fontWeight: 'var(--ebl-noto)',
    },
    '& [data-se="forgot-password"]': {
      textDecoration: 'var(--ebl-link-normal-text-decoration)',
      color: theme.palette.ebl.globalTextlinkcolor,
    },
    '& [data-se="o-form-input-container"] > span': {
      display: 'block',
      position: 'relative',
      '&:hover': {
        '& fieldset': {
          borderColor: theme.palette.ebl.formelementBorderColorHover,
        },
        '& .error': {
          borderColor: theme.palette.ebl.formelementBorderColorError,
        },
      },
    },
    '& [data-se="cancel"]': {
      '-webkit-box-pack': 'center',
      '-webkit-box-align': 'center',
      '-webkit-tap-highlight-color': 'transparent',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      boxSizing: 'border-box',
      outline: 0,
      cursor: 'pointer',
      userSelect: 'none',
      verticalAlign: 'middle',
      textDecoration: 'none',
      lineHeight: 'var(--ebl-sh4-line-height)',
      letterSpacing: 'var(--ebl-sh4-letter-spacing)',
      textTransform: 'none',
      minWidth: 64,
      width: 'fit-content',
      transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      fontFamily: 'var(--ebl-wf-headings)',
      fontWeight: 'var(--ebl-wf-headings-weight)',
      borderRadius: 'var(--ebl-button-radius)',
      overflow: 'visible',
      paddingBlock: 1,
      paddingInline: 6,
      textAlign: 'center',
      textShadow: 'none',
      textIndent: 0,
      wordSpacing: 'normal',
      textRendering: 'auto',
      margin: 'var(--ebl-s2) 0',
      backgroundColor: theme.palette.ebl.buttonOutlinedDefaultBackgroundcolor,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.ebl.buttonOutlinedBorderColor,
      color: theme.palette.ebl.buttonOutlinedTextColor,
      height: 'var(--ebl-button-height-sm)',
      fontSize: 'var(--ebl-button-text-size-sm)',
      padding: '7px var(--ebl-s4)',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '& [data-se="go-back"]': {
      '-webkit-box-pack': 'center',
      '-webkit-box-align': 'center',
      '-webkit-tap-highlight-color': 'transparent',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      boxSizing: 'border-box',
      outline: 0,
      cursor: 'pointer',
      userSelect: 'none',
      verticalAlign: 'middle',
      textDecoration: 'none',
      lineHeight: 'var(--ebl-sh4-line-height)',
      letterSpacing: 'var(--ebl-sh4-letter-spacing)',
      textTransform: 'none',
      minWidth: 64,
      width: 'fit-content',
      transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      fontFamily: 'var(--ebl-wf-headings)',
      fontWeight: 'var(--ebl-wf-headings-weight)',
      borderRadius: 'var(--ebl-button-radius)',
      overflow: 'visible',
      paddingBlock: 1,
      paddingInline: 6,
      textAlign: 'center',
      textShadow: 'none',
      textIndent: 0,
      wordSpacing: 'normal',
      textRendering: 'auto',
      margin: 'var(--ebl-s2) 0',
      backgroundColor: theme.palette.ebl.buttonOutlinedDefaultBackgroundcolor,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.ebl.buttonOutlinedBorderColor,
      color: theme.palette.ebl.buttonOutlinedTextColor,
      height: 'var(--ebl-button-height-sm)',
      fontSize: 'var(--ebl-button-text-size-sm)',
      padding: '7px var(--ebl-s4)',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '& .o-form-error-container': {
      '& [data-se="o-form-explain"]': {
        display: 'none',
      },
      '& .buttonContainer': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
          justifyContent: 'start',
          flexDirection: 'column',
        },
      },
    },
    '& .o-form-info-container': {
      display: 'none',
    },
    '& .identifier-container': {
      display: 'none',
    },
    "& [data-se='callout']": {
      display: 'none',
    },
  },
}));
