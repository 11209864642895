import { ALERT_SIZE, HcpAlert, SEVERITY } from '@hmhco/hcp-alert/src/index';
import React, { useEffect, useRef, useState } from 'react';

/**
 * Should be used to display generic info messages from Okta form.
 *
 */
const OktaInfoAlert = () => {
  const [show, setShow] = useState(false);
  const infoAlertContainerRef = useRef(null);
  const mounted = useRef(false);

  useEffect(() => {
    if (infoAlertContainerRef.current) {
      const observer = new MutationObserver(() => {
        if (infoAlertContainerRef.current) {
          const hidden = infoAlertContainerRef.current.getAttribute('hidden');
          setShow(hidden === null || hidden === undefined);
        }
      });
      observer.observe(infoAlertContainerRef.current, {
        attributes: true,
        attributeFilter: ['hidden'],
      });
      return () => {
        observer.disconnect();
      };
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }

    if (infoAlertContainerRef.current) {
      infoAlertContainerRef.current.hidden = !show;
    }
  }, [show]);

  return (
    <div
      hidden
      ref={infoAlertContainerRef}
      id="okta-info-alert"
      data-testid="okta-info-alert"
    >
      <HcpAlert
        size={ALERT_SIZE.LARGE}
        severity={SEVERITY.INFO}
        title=""
        subtitle=""
        onClose={() => setShow(false)}
      />
    </div>
  );
};

export default OktaInfoAlert;
